<template>
  <div class="richEditor" :class="$route.path.includes('templates') ? '-template' : ''">
    <vs-input
      class="input-editor"
      :id="id"
      v-if="showHeader"
      :placeholder="placeholder"
      v-model="title"
      maxlength="80"
    />
    <slot name="files" />
    <div class="d-flex options-editor" v-if="showHeader">
      <div class="menu-rich" v-click-outside="hide" v-if="showMenu">
        <vs-input
          class="template-title input-client"
          placeholder="Buscar plantilla"
          v-model="searchText"
        />
        <div class="results-wrapper">
          <div class="results-empty" v-if="getTemplatesComputed.length === 0">
            <img src="@/assets/img/EmptyState.svg" class="icon" />
            No se han encontrado resultados
          </div>
          <div class="templates-space" v-else>
            <div
              class="templates"
              align="left"
              v-for="tr in getTemplatesComputed"
              :key="tr.id"
              :data="tr"
              @click="assignedTemplate(tr)"
            >
              <span>{{ tr.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <vs-button
        class="button-insert-template"
        border
        color="#ebebeb"
        @click.prevent="showMenuSearch"
        v-if="options"
      >
        {{ !showMenu ? 'Insertar plantilla' : 'Cerrar ventana' }}
      </vs-button>
      <vs-checkbox v-model="option" v-if="options">Guardar como plantilla</vs-checkbox>
    </div>
    <vue-editor
      :id="idEditor"
      class="rich-vue"
      :class="{ richVueEditor: templatesType }"
      :spellcheck="false"
      :placeholder="descriptionPlaceholder"
      v-model="content"
      :editor-toolbar="customToolbar"
    />
  </div>
</template>

<script>
import { VueEditor, } from 'vue2-editor'
import { ClickOutside, } from 'vue-click-outside'

export default {
  data () {
    return {
      content: '',
      datarooms: [],
      dataroomsName: [],
      deleteRoom: [],
      nameDatarooms: [],
      fileToUpload: [],
      role: this.$store.state.AuthModule.AuthState.role,
      option: false,
      showMenu: false,
      page: 1,
      filter_go: [],
      title: '',
      searchText: '',
      templates: this.$store.state.RecomendationModule.RecomendationState.templates_small,
      customToolbar: [
        ['bold', 'italic', 'underline',],
        [{ size: ['small', false, 'large', 'huge',], },],
        [{ list: 'ordered', }, { list: 'bullet', },],
        ['image', 'code-block',],
        [{ align: '', }, { align: 'center', }, { align: 'right', }, { align: 'justify', },],
        [{ indent: '-1', }, { indent: '+1', },],
        [{ color: [], }, { background: [], },],
      ],
    }
  },
  props: {
    options: {
      type: Boolean,
      default: true,
    },
    templatesType: {
      type: Boolean,
      default: false,
    },
    titleTemplate: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    idEditor: {
      type: String,
      required: false,
      default: '',
    },
    contentTemplate: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Título (requerido)',
    },
    descriptionPlaceholder: {
      type: String,
      required: false,
      default: 'Descripción (requerido)',
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getTemplatesComputed () {
      let filtered = this.templates
      if (this.searchText) {
        filtered = this.templates.filter(
          (item) => item.title.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
        )
        return filtered
      } else {
        return this.templates
      }
    },
  },
  directives: {
    ClickOutside,
  },
  components: {
    VueEditor,
    // eslint-disable-next-line vue/no-unused-components
    ClickOutside,
  },
  watch: {
    title () {
      this.$emit('emitTitle', this.title)
    },
    content () {
      this.$emit('emitContent', this.content)
    },
    option () {
      this.$emit('emitSaveAs', this.option)
    },
  },
  mounted () {
    setTimeout(() => {
      this.title = this.titleTemplate
      this.content = this.contentTemplate
    }, 1000)
  },
  methods: {
    assignedTemplate (template) {
      this.$store
        .dispatch('RecomendationModule/getTemplatesbyId', Number(template.id))
        .then((response) => {
          this.title = response.data.template.title
          this.content = response.data.template.description
          this.$emit('toDataroom', response.data.template)
          this.showMenu = false
        })
    },
    hide () {
      this.showMenu = false
    },
    showMenuSearch () {
      this.showMenu = !this.showMenu
    },
  },
}
</script>

<style lang="scss">
.quillWrapper .ql-snow.ql-toolbar {
  position: absolute;
  z-index: 10;
  bottom: 5px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 4px;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: $light;
}

.input-editor .vs-input {
  width: 100%;
  padding: 11px;
  border-bottom: 1px solid $gray-90;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-size: $default;

  ~ label {
    font-size: $default;
  }
}

.templates-space {
  width: 100%;
  height: 245px;
  overflow: auto;
}

.templates {
  width: 100%;
  padding: 2px 4px;
  transition: background-color .3s ease;
  cursor: pointer;

  &:hover {
    background: $hm-blue-light;
  }
}

.menu-rich {
  position: absolute;
  z-index: 12;
  top: 40px;
  right: 0;
  width: 500px;
  height: 280px;
  border: 1px solid $gray-90;
  border-radius: 6px;
  background: $light;
  box-shadow: 0 0 8px rgba(40, 40, 40, .15);
  text-align: left;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: none;
}

.quillWrapper {
  position: relative;
  padding-bottom: 70px;
}

.richVueEditor {
  height: calc(100vh - 320px);
  padding-bottom: 85px;
}

.richEditor {
  position: relative;
  border: 1px solid $gray-60;
  border-radius: 6px;

  &.-template {
    border-radius: 0;
    border-color: transparent;
  }
}

.position-icon {
  position: absolute;
  z-index: 20;
  bottom: -3px;
  left: 52%;
}

.options-editor {
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;

  .vs-checkbox-label {
    font-size: $label;
  }
}

.button-insert-template {
  margin-right: 20px;

  .vs-button__content {
    color: $gray-35;
  }
}

.ql-container .ql-editor {
  padding-bottom: 50px;

  &.ql-blank:before {
    color: rgba(0, 0, 0, .3);
    font-style: normal;
  }
}

.results-wrapper {
  .results-empty {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;

    > .icon {
      margin: 20px 0;
    }
  }
}
</style>
